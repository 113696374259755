.intro {
  &__inner {
    display: flex;
    justify-content: center;
    padding-bottom: 200px;
    position: relative;
    max-width: 1618px;
    padding-left: 24px;
    padding-right: 24px;
    margin-left: auto;
    margin-right: auto;
  }
  &__bg {
    position: absolute;
    left: -60px;
    top: 0;
    width: 1132.59px;
    z-index: -1;
    // height: 1067.95px;
    &-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__info {
    transform: translateX(50%);
    margin-top: 352px;
    max-width: 534px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  &__title {
    font-weight: 900;
    font-size: 62px;
    line-height: 109%;
    color: #ffffff;
  }
  &__subtitle {
    font-weight: 500;
    font-size: 21px;
    line-height: 160%;
    color: rgba(#ffffff, 0.6);
  }
  &-comunity {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    &__title {
      font-weight: 400;
      font-size: 13px;
      line-height: 140%;
      color: #595959;
    }
    &__buttons {
      display: flex;
      align-items: center;
      gap: 16px;
      .button {
        width: 180.82px;
      }
    }
  }
  @media (max-width: 930px) {
    &__inner {
      padding-bottom: 64px;
    }
    &__bg {
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
    }
    &__info {
      max-width: 100%;
      transform: translateX(0);
      text-align: center;
      margin-top: 90%;
    }
    &__title {
      font-size: 42px;
    }
    &-comunity__buttons {
      justify-content: center;
    }
  }
  @media (max-width: 670px) {
    &__bg {
      width: 150%;
    }
    &__info {
      margin-top: 150%;
    }
  }
  @media (max-width: 500px) {
    &__bg {
      width: 200%;
    }
    &-comunity {
      margin-top: 0;
      &__buttons {
        flex-direction: column;
        .button {
          width: 100%;
        }
      }
    }
    &__info {
      margin-top: 200%;
    }
  }
}
