.team {
  padding-top: 144px;
  &__inner {
    position: relative;
    padding-bottom: 96px;
    padding-left: 0;
    padding-right: 0;
    &::before {
      content: "";
      width: 100%;
      background: linear-gradient(136.32deg, #f99f2f 16.32%, #6959b1 85.53%);
      opacity: 0.1;
      filter: blur(235.137px);
      height: 1273.5px;
      position: absolute;
      left: 0;
      top: -162px;
      z-index: -1;
    }
  }
  .title {
    margin-bottom: 64px;
  }

  &__people {
    display: flex;
    flex-wrap: wrap;
    gap: 42px;
    justify-content: center;
  }
  &__slide {
    max-width: 288px;
  }
  @media (max-width: 500px) {
    padding-top: 40px;
    &__inner {
      padding-bottom: 0;
    }
  }
}
