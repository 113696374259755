$colors: (
  "lightblue": "linear-gradient(82.77deg, #7C9EC3 5.38%, #7A95AA 44.75%)",
  "yellow": "linear-gradient(82.77deg, #ad6640 5.38%, #794328 44.75%)",
  "brown": "linear-gradient(82.77deg, #D4AB79 5.38%, #D9AA5E 44.75%)",
  "grey": "linear-gradient(82.77deg, #c9b398 5.38%, #c1c0c0 44.75%)",
  "green": "linear-gradient(82.77deg, #30ca72 5.38%, #429841 44.75%)",
  "lightgreen": "linear-gradient(82.77deg, #90ae3c 5.38%, #79993c 44.75%)",
  "blue": "linear-gradient(82.77deg, #aee3f9 5.38%, #41b3cc 44.75%)",
);

.goal {
  padding-top: 144px;
  &__inner {
    max-width: 1328px;
    padding-left: 24px;
    padding-right: 24px;
    margin-left: auto;
    margin-right: auto;
  }

  &__subtitle {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #96764b;
    margin-bottom: 24px;
  }
  .title {
    max-width: 751px;
    margin: 0 auto;
    margin-bottom: 154px;
  }
  &-road {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 847.76px;
    width: 100%;
    height: 1592.63px;
    background: url("../../../assets/goal/goalBG.svg") center / contain no-repeat;
    position: relative;
    margin: 0 auto;
    margin-bottom: 78.37px;
    &-item {
      $roadItem: &;
      max-width: 530px;
      width: 100%;
      height: 240px;
      background: #171616;
      border-radius: 0px 0px 24px 24px;
      position: absolute;
      &:nth-child(2n + 1) {
        left: -161px;
      }
      &:nth-child(2n) {
        right: -191.24px;
      }
      &:nth-child(1) {
        top: -90px;
      }
      &:nth-child(2) {
        top: 118px;
      }
      &:nth-child(3) {
        top: 433px;
      }
      &:nth-child(4) {
        top: 683px;
      }
      &:nth-child(5) {
        top: 928px;
      }
      &:nth-child(6) {
        top: 1139px;
      }
      &:nth-child(7) {
        top: 1431px;
      }
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 8px;
      }
      &__inner {
        display: flex;
        align-items: center;
        gap: 28px;
        height: 100%;
        padding: 29px 40px 21px 40px;
      }

      &__image {
        max-width: 140px;
      }

      &__image-img {
        max-width: 100%;
        object-fit: cover;
      }

      &__content {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }

      &__points {
        display: flex;
        align-items: center;
        gap: 8px;
      }

      &__point {
        width: 22.71px;
        height: 24px;
        background: url("../../../assets/goal/monetka.png") center / cover no-repeat;
        opacity: 0.1;
        &-active {
          transition: opacity 0.5s;
          opacity: 1;
        }
      }

      &__title {
        font-weight: 900;
        font-size: 32px;
        line-height: 100%;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }

      &__text {
        font-weight: 500;
        font-size: 21px;
        line-height: 160%;
        color: rgba(#ffffff, 0.6);
      }
      @each $name, $value in $colors {
        &-#{$name} {
          #{$roadItem}__title,
          &::before {
            background-image: #{$value};
          }
        }
      }
    }
  }
  @media (max-width: 1270px) {
    .title {
      margin-bottom: 49px;
    }
    &-road {
      margin-bottom: 0;
      justify-content: center;
      height: auto;
      background: none;
      gap: 24px;
      &-item {
        top: auto !important;
        left: auto !important;
        right: auto !important;
        bottom: auto !important;
        position: relative;
      }
    }
  }
  @media (max-width: 500px) {
    padding-top: 40px;
    &-road {
      &-item {
        height: auto;
        &__inner {
          flex-direction: column;
          padding: 48px 40px 40px 40px;
        }
        &__content {
          width: 100%;
          text-align: center;
        }
        &__points {
          justify-content: center;
          order: 3;
        }
        &__title {
          order: 1;
        }
        &__text {
          order: 2;
        }
      }
    }
  }
}
