.mission {
  padding: 138px 24px 0 24px;
  position: relative;
  &::before {
    content: "";
    width: 100%;
    height: 1273.5px;
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(136.32deg, #f99f2f 16.32%, #6959b1 85.53%);
    opacity: 0.1;
    filter: blur(235.137px);
    z-index: -1;
  }
  &__inner {
    background: linear-gradient(180deg, rgba(35, 34, 32, 0.4) 0%, rgba(35, 34, 32, 0) 123.88%);
    border-radius: 60px;
    padding: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .title {
    margin-bottom: 23px;
    max-width: 670px;
  }

  &__engine {
    margin-bottom: 112px;
  }
  &-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 112px;
    &__item {
      font-family: "Inter";
      padding: 24px;
      max-width: 280px;
      width: 100%;
    }

    &__title {
      font-weight: 800;
      font-size: 24px;
      line-height: 32px;
      padding-bottom: 24px;
      margin-bottom: 24px;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 3px;
        background: linear-gradient(82.77deg, #fbf6cd 5.38%, #f99f2f 21.58%, #fbf6cd 44.75%);
        border-radius: 20px;
      }
    }

    &__text {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      color: rgba(#ffffff, 0.6);
    }
  }
  &-available {
    &__title {
      font-family: "Inter";
      font-weight: 800;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      letter-spacing: -0.019em;
      margin-bottom: 33px;
    }
    &__items {
      display: flex;
      align-items: center;
      gap: 24px;
    }
    &__item {
    }

    &__img {
    }
  }
  @media (max-width: 1200px) {
    &__engine {
      margin-bottom: 50px;
    }
    .title {
      font-size: 54px;
      line-height: 54px;
    }
    &-info {
      flex-wrap: wrap;
      margin-bottom: 50px;
      &__item {
        min-width: 327px;
        max-width: 50%;
        width: 100%;
      }
    }
  }
  @media (max-width: 865px) {
    &__inner {
      padding: 60px 40px;
    }
  }
  @media (max-width: 785px) {
    &__inner {
      padding: 40px 24px;
    }
  }
  @media (max-width: 750px) {
    &__inner {
      background: none;
      padding: 0;
    }
  }
  @media (max-width: 702px) {
    padding: 50px 24px 0 24px;
    &-info {
      gap: 24px;
      &__item {
        padding: 0;
        min-width: 100%;
        max-width: 100%;
      }
    }
  }
  @media (max-width: 500px) {
    .title {
      font-size: 40px;
    }
    &__engine,
    &-info {
      margin-bottom: 40px;
    }
  }
}
