.modal {
  &__item {
    max-width: 33.333%;
    width: 100%;
  }

  &__link {
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
    letter-spacing: 0.02em;
    color: #fff;
  }
}
