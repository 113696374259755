.header {
  position: fixed;
  left: 0;
  top: 0;
  padding: 30px 0;
  width: 100%;
  z-index: 10;
  &-sticky {
    transition: background 0.5s;
    background: rgba(19, 18, 18, 0.6);
    backdrop-filter: blur(8px);
  }
  &__inner {
    display: flex;
    align-items: center;
    gap: 110px;
    max-width: 1618px;
    padding-left: 24px;
    padding-right: 24px;
    margin-left: auto;
    margin-right: auto;
  }
  &__contact {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #ffffff;
  }
  &__info {
    display: flex;
    align-items: center;
    gap: 16px;
    .button {
      width: auto;
    }
  }
  &__share {
    display: none;
  }
  @media (max-width: 1500px) {
    &__inner {
      gap: 70px;
    }
  }
  @media (max-width: 1420px) {
    &__inner {
      gap: 50px;
    }
  }
  @media (max-width: 1330px) {
    &__contact,
    &__share {
      display: none;
    }
  }
  @media (max-width: 1122px) {
    &__info {
      display: none;
    }
  }
  @media (max-width: 670px) {
    &__inner {
      justify-content: space-between;
    }
  }
  @media (max-width: 500px) {
    padding: 25px 0;
  }
}
