.person {
  max-width: 288px;
  &__image {
    height: 160px;
    position: relative;
    &-photo {
      border-radius: 24px 24px 0 0;
      max-width: 100%;
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
    &-linkedin {
      position: absolute;
      left: 24px;
      bottom: 24px;
      display: none;
    }
  }
  &__info {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px;
    position: relative;
    background: linear-gradient(180deg, rgba(35, 34, 32, 0.4) 0%, rgba(35, 34, 32, 0) 123.88%);
  }

  &__name {
    font-family: "Inter", serif;
    font-weight: 800;
    font-size: 48px;
    line-height: 32px;
    color: #ffffff;
  }
  &__position {
    font-family: "Inter", serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    background: linear-gradient(82.77deg, #4da9ff 5.38%, #54e0ff 44.75%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  &-description {
    font-weight: 400;
    font-size: 15px;
    line-height: 140%;
    color: rgba(#ffffff, 0.4);
    position: absolute;
    z-index: 3;
    opacity: 0;
    &__item {
      padding-left: 16px;
      position: relative;
      transition: all 0.5s;
      &::before {
        content: "";
        width: 4px;
        height: 4px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background: #96764b;
        border-radius: 50%;
      }
    }
  }
  &:hover {
    position: relative;
    .person__info {
      background: #16141a;
      box-shadow: 0 24px 40px rgba(0, 0, 0, 0.45);
    }
    .person-description {
      padding: 0 24px 31px 24px;
      left: 0;
      width: 100%;
      background: #16141a;
      box-shadow: 0 24px 40px rgba(0, 0, 0, 0.45);
      border-radius: 0 0 24px 24px;
      opacity: 1;
      top: 100%;
    }
  }
  @media (max-width: 768px) {
    background: rgba(56, 56, 56, 0.3);
    border-radius: 24px;
    max-width: 260px;
    &__name {
      font-size: 28px;
      line-height: 32px;
    }
    &__position {
      font-size: 16px;
      line-height: 18px;
    }
    &-description {
      position: relative;
      opacity: 1;
      background: none;
    }
    &__info {
      background: #262626;
      padding: 24px 24px 64px 24px;
      border-radius: 0 0 24px 24px;
    }
    &:hover {
      position: relative;
      .person__info {
        background: #262626;
        box-shadow: none;
      }
      .person-description {
        padding: 0;
        width: 100%;
        background: none;
        box-shadow: none;
        border-radius: 0;
        opacity: 1;
        top: auto;
        left: auto;
      }
    }
  }
}
