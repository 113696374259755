.distribution {
  padding-top: 144px;
  margin-bottom: 60px;
  &__inner {
    padding: 84px 24px 40px 24px;
    background: linear-gradient(180deg, rgba(35, 34, 32, 0.4) 0%, rgba(35, 34, 32, 0) 123.88%);
    border-radius: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      background: linear-gradient(136.32deg, #f99f2f 16.32%, #6959b1 85.53%);
      opacity: 0.1;
      top: 63px;
      left: 0;
      filter: blur(235.137px);
      z-index: -1;
      height: 1273.5px;
    }
  }
  .title {
    margin-bottom: 24px;
  }

  &__image {
    overflow-x: auto;
    max-width: 100%;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
    &-inner {
      max-width: 100%;
      text-align: center;
      overflow-x: auto;
      -ms-overflow-style: none; /* Internet Explorer 10+ */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }
    }
    &-img {
      width: 1079.88px;
      object-fit: cover;
    }
  }
  &-total {
    margin: 0 auto 24px auto;
    max-width: 350px;
    width: 100%;
    padding: 18px 32px;
    border: 1px solid #2c2c2c;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__title {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.03em;
      text-transform: uppercase;
      color: #ffffff;
    }

    &__number {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.03em;
      text-transform: uppercase;
      background: linear-gradient(82.77deg, #f99f2f 5.38%, #fbf6cd 44.75%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }
  &__separator {
    width: 100%;
    height: 3px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) -6.48%, #ffffff 45.65%, rgba(255, 255, 255, 0) 100%);
    opacity: 0.4;
    border-radius: 20px;
    margin: 40px 0;
  }
  @media (max-width: 1330px) {
    &__inner {
      padding: 60px 0px 40px 0px;
    }
    &__image {
      padding-left: 24px;
    }
  }
  @media (max-width: 550px) {
    padding-top: 80px;
    margin-bottom: 0;
    &__inner {
      padding-top: 0;
      background: none;
    }
    &-total {
      width: calc(100% - 48px);
      max-width: 100%;
      flex-direction: column;
      gap: 20px;
    }
    &__separator,
    .button {
      width: calc(100% - 48px);
    }
  }
}
