.logo {
  max-height: 50px;
  width: 88.15px;
  &-img {
    max-height: 100%;
    max-width: 100%;
    object-fit: cover;
  }
  @media (max-width: 500px) {
    width: 52px;
    max-height: 30px;
  }
}
