.road {
  padding-top: 144px;
  &__inner {
    width: 100%;
    position: relative;
    &::before,
    &::after {
      content: "";
      position: absolute;
      z-index: -1;
    }
    &::before {
      background: #2980d0;
      opacity: 0.15;
      filter: blur(150px);
      transform: rotate(-45deg);
      height: 819.6425030622596px;
      width: 719.2118407185571px;
      left: -450px;
      top: -50px;
    }
    &::after {
      background: #f59902;
      opacity: 0.1;
      filter: blur(150px);
      right: -630px;
      bottom: -116px;
      height: 468px;
      width: 908px;
    }
  }
  .title {
    margin-bottom: 85px;
  }

  &__years {
    overflow-x: auto;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
    &-inner {
      display: flex;
      justify-content: space-between;
      width: 100%;
      gap: 24px;
      overflow-x: auto;
      width: 1280px;
      -ms-overflow-style: none; /* Internet Explorer 10+ */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }
    }
  }
  @media (max-width: 1330px) {
    &__inner {
      padding: 0;
    }
    &__years {
      padding-left: 24px;
    }
  }
  @media (max-width: 500px) {
    padding-top: 80px;
  }
}
