.button {
  background: rgba(255, 255, 255, 0.04);
  border: 1px solid #333333;
  border-radius: 12.5406px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #ffffff;
  padding: 12px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  &:hover {
    border: 1px solid #96764b;
  }
  &-green {
    background: linear-gradient(90deg, #66bf31 0%, #308a33 100%);
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2);
    &:hover {
      background: linear-gradient(264.36deg, #7aff2b -9.35%, #f99f2f 98.01%);
    }
  }
  &-blue {
    background: rgb(0, 108, 255);
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2);
    &:hover {
      border: 1px solid rgba(0, 108, 255, 0.7);
      background: rgba(0, 108, 255, 0.7);
    }
  }
  @media (max-width: 1500px) {
    font-size: 14px;
  }
}
