.modal {
  background: rgba(19, 18, 18, 0.8);
  border: 1px solid #96764b;
  backdrop-filter: blur(17px);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  overflow-y: auto;
  display: none;
  &-active {
    display: block;
  }
  &__inner {
    padding: 173.5px 24px;
    display: flex;
    flex-direction: column;
    gap: 75px 0;
    overflow-y: auto;
  }
  &__close {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__close-text {
    font-weight: 700;
    font-size: 21px;
    line-height: 32px;
    letter-spacing: 0.02em;
  }

  &__close-crest {
    width: 18.38px;
    height: 18.38px;
    position: relative;
    &::before,
    &::after {
      content: "";
      position: absolute;
      background: #d9d9d9;
      border-radius: 1px;
      width: 2px;
      height: 24px;
      right: 8px;
      top: -3px;
    }
    &::before {
      transform: rotate(-45deg);
    }
    &::after {
      transform: rotate(45deg);
    }
  }

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__head-title {
    font-weight: 900;
    font-size: 62px;
    line-height: 80px;
    background: linear-gradient(82.77deg, #f99f2f 5.38%, #fbf6cd 44.75%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  &__buttons {
    display: flex;
    align-items: center;
    gap: 24px;
    .button {
      width: 160px;
    }
  }

  &__body {
  }
  //TODO
  &__body-title {
    background: linear-gradient(152.08deg, #d4ab79 47.7%, #d9aa5e 168.73%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin-bottom: 16px;
  }

  &__menu {
  }

  &__list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    max-width: 900px;
  }
  &__item {
    &:hover {
      background: linear-gradient(152.08deg, #d4ab79 47.7%, #d9aa5e 168.73%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }
  &-footer {
    display: flex;
    align-items: center;
    gap: 16px;
    &__item {
    }

    &__link {
      font-family: "Inter", sans-serif;
      font-weight: 800;
      font-size: 14px;
      line-height: 32px;
      color: #fff;
    }
  }
  .socio {
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
    &__link {
      min-width: 146px;
      max-width: 146px;
      height: 100px;
    }
  }
  @media (max-height: 1320px) {
    &-footer {
      justify-content: center;
    }
  }
  @media (max-height: 1085px) {
    &__inner {
      padding: 100px 24px;
    }
  }
  @media (max-height: 939px) {
    &__inner {
      padding: 50px 24px;
    }
  }
  @media (max-height: 839px) {
    &__inner {
      padding: 24px;
    }
  }
  @media (max-height: 786px) {
    &__inner {
      gap: 50px;
    }
  }
  @media (max-height: 686px) {
    &__inner {
      gap: 25px;
    }
  }
  @media (max-width: 1330px) {
    &__link {
      font-size: 25px;
      line-height: 40px;
    }
    &__head-title {
      font-size: 55px;
      line-height: 68px;
    }
  }
  @media (max-width: 936px) {
    &__head-title {
      font-size: 40px;
      line-height: 57px;
    }
    &__link {
      font-size: 20px;
      line-height: 32px;
    }
  }
  @media (max-width: 874px) {
    &__close {
      justify-content: center;
    }

    &__head {
      flex-direction: column;
      gap: 24px;
    }
  }
  @media (max-width: 685px) {
    .socio,
    &__head-title {
      display: none;
    }
    &__list {
      gap: 8px;
    }
    &__item {
      max-width: none;
      border: 1px solid rgba(102, 102, 102, 0.2);
      border-radius: 8px;
      padding: 28px;
      text-align: center;
      flex: 1 1 33.333%;
    }
    &__link {
      font-size: 16px;
      line-height: 24px;
    }
    &__close {
      order: 1;
    }
    &__body {
      order: 2;
    }
    &__head {
      order: 3;
    }
    &-footer {
      display: none;
    }
    &__inner {
      padding: 52px 24px;
    }
  }
  @media (max-width: 575px) {
    .button {
      width: 100%;
    }
    &__inner {
      gap: 35px;
    }
    &__buttons {
      width: 100%;
      flex-direction: column;
      gap: 8px;
    }
    &__item {
      display: flex;
      align-items: stretch;
      justify-content: center;
      padding: 20px 3px;
    }
  }
  @media (max-width: 500px) {
    &-footer {
      order: 7;
      gap: 24px;
      display: flex;
    }
  }
}
