.expirience {
  padding-top: 80px;
  &__inner {
    background: linear-gradient(180deg, rgba(35, 34, 32, 0.4) 0%, rgba(35, 34, 32, 0) 123.88%);
    border-radius: 60px;
    padding: 84px 80px 52px 80px;
  }
  .title {
    margin-bottom: 23px;
  }
  &__subtitle {
    font-weight: 500;
    font-size: 21px;
    line-height: 160%;
    text-align: center;
    color: rgba(#ffffff, 0.6);
    max-width: 575px;
    margin: 0 auto 80px auto;
  }
  &__items {
    display: flex;
    gap: 80px;
    justify-content: center;
    flex-wrap: wrap;
  }
  &__item {
    max-width: 200px;
    width: 100%;
    margin-bottom: 64px;
  }
  &__number {
    font-family: "Inter";
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.019em;
    background: linear-gradient(82.77deg, #f99f2f 5.38%, #fbf6cd 44.75%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin-bottom: 16px;
  }
  &__text {
    font-family: "Inter";
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: -0.019em;
    color: rgba(#ffffff, 0.6);
  }
  &__companies {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 24px;
    margin-bottom: 24px;
  }
  &__company {
    max-width: 166.67px;
    width: 100%;
    height: 100px;
    border: 1px solid #2c2c2c;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-game {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    flex-wrap: wrap;
    &__item {
      border: 1px solid #2c2c2c;
      border-radius: 24px;
      max-width: 262px;
      width: 100%;
      height: 168px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 16px;
      gap: 16px;
    }

    &__image {
    }

    &__img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &__content {
      display: flex;
      flex-direction: column;
      gap: 4px;
      align-items: center;
      justify-content: center;
    }
    &__element {
      font-weight: 400;
      font-size: 13px;
      line-height: 140%;
      display: flex;
      align-items: center;
      gap: 4px;
    }
    &__title {
    }

    &__text {
      color: #fbf6cd;
    }
  }
  @media (max-width: 1280px) {
    &__inner {
      padding: 60px 40px 52px 40px;
    }
  }
  @media (max-width: 1200px) {
    &__inner {
      padding: 40px 24px;
    }
  }
  @media (max-width: 1000px) {
    &__items {
      gap: 24px;
    }
  }
  @media (max-width: 500px) {
    padding-top: 40px;
    &__inner {
      background: none;
      padding-top: 0;
    }
    &__items {
      padding: 0 40px;
    }
    &__item {
      max-width: 100%;
    }
    &__company {
      max-width: 151.5px;
    }
    &-game__item {
      max-width: 100%;
    }
    &__subtitle {
      margin: 0 auto 64px auto;
      font-size: 13px;
    }
  }
}
