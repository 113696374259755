.attachment {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;
  margin-left: auto;
  margin-right: auto;
  .prize {
    img {
      max-width: 100%;
      object-fit: cover;
    }
  }
  @media (max-width: 1330px) {
    position: absolute;
    top: 131px;
    bottom: auto;
    .adviser {
      display: none;
    }
  }
  @media (max-width: 500px) {
    .prize {
      max-width: 98px;
    }
  }
}
