@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500;700;800&family=Nunito:wght@800&family=Poppins:wght@400;500;600;700;900&display=swap');

$fontfamilyPopins: 'Poppins', sans-serif;
$fontfamilyInter: 'Inter', sans-serif;
// font-family: 'Nunito', sans-serif;

* {
  padding: 0;
  margin: 0;
  border: 0;
}
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
:focus,
:active {
  outline: none;
}
a:focus,
a:active {
  outline: none;
}
nav,
footer,
header,
aside,
section {
  display: block;
}
html,
body {
  height: 100%;
  width: 100%;
}
body {
  font-family: $fontfamilyPopins;
  font-style: normal;
  font-weight: normal;
  color: #ffffff;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  background: #000000;
}
input,
button,
textarea {
  font-family: $fontfamilyPopins;
}
input::-ms-clear {
  display: none;
}
button {
  cursor: pointer;
}
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
a,
a:visited {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
ul li {
  list-style: none;
}
img {
  vertical-align: top;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  font-family: $fontfamilyPopins;
  scroll-behavior: smooth;
}

.wrapper {
  position: relative;
  overflow: hidden;
}
.container {
  max-width: 1328px;
  padding-left: 24px;
  padding-right: 24px;
  margin-left: auto;
  margin-right: auto;
}