$brown: linear-gradient(93.35deg, #ad6640 6.29%, #794328 97.88%);
$green: linear-gradient(123.98deg, #30ca72 30.32%, #429841 106.48%);
$blue: linear-gradient(138.83deg, #aee3f9 39.38%, #41b3cc 115.64%);
$yellow: linear-gradient(152.08deg, #d4ab79 47.7%, #d9aa5e 168.73%);

.road-year {
  $rootParent: &;
  width: 302px;
  &-brown {
    #{$rootParent}__number,
    #{$rootParent}__items::before,
    #{$rootParent}__item::before,
    #{$rootParent}__month {
      background-image: $brown;
    }
  }
  &-green {
    #{$rootParent}__number,
    #{$rootParent}__items::before,
    #{$rootParent}__item::before,
    #{$rootParent}__month {
      background-image: $green;
    }
  }
  &-blue {
    #{$rootParent}__number,
    #{$rootParent}__items::before,
    #{$rootParent}__item::before,
    #{$rootParent}__month {
      background-image: $blue;
    }
  }
  &-yellow {
    #{$rootParent}__number,
    #{$rootParent}__items::before,
    #{$rootParent}__item::before,
    #{$rootParent}__month {
      background-image: $yellow;
    }
  }
  &__number {
    font-weight: 900;
    font-size: 36px;
    line-height: 45px;
    text-align: center;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin-bottom: 21px;
  }
  &__items {
    background: linear-gradient(180deg, rgba(35, 34, 32, 0.4) 0%, rgba(35, 34, 32, 0) 123.88%);
    border-radius: 0 0 24px 24px;
    position: relative;
    padding-top: 48px;
    padding-bottom: 80px;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 40px;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: 8px;
      width: 100%;
    }
  }

  &__item {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 16px;
    padding-left: 49px;
    padding-right: 30px;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      left: 24px;
      top: 50%;
      transform: translateY(-50%);
      width: 8px;
      height: 8px;
      border-radius: 50%;
    }
  }

  &__image {
    max-width: 80px;
  }

  &__image-img {
    max-width: 100%;
    width: 100%;
    height: 100%;
  }

  &__info {
    max-width: 129px;
  }

  &__month {
    font-family: "Nunito", serif;
    display: none;
    font-weight: 800;
    font-size: 27px;
    line-height: 37px;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  &__description {
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: rgba(#ffffff, 0.6);
  }
}
