.copy {
  padding-bottom: 60px;
  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }

  &__links {
    display: flex;
    align-items: center;
    gap: 24px;
  }

  &__link {
    font-family: "Inter";
    font-weight: 800;
    font-size: 16px;
    line-height: 32px;
    color: #ffffff;
  }

  &__description {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 32px;
    text-align: center;
    color: #ffffff;
  }
  @media (max-width: 500px) {
    &__links {
      width: 100%;
      align-items: flex-start;
      flex-direction: column;
    }
  }
}
