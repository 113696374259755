.menu {
  display: flex;
  align-items: center;
  gap: 16px;
  flex-grow: 1;
  &__burger {
    width: 24px;
    height: 12px;
    position: relative;
    cursor: pointer;
    &::before,
    &::after,
    span {
      content: "";
      background: #d9d9d9;
      border-radius: 1px;
      position: absolute;
      right: 0;
      height: 2px;
      transition: all 0.3s;
    }
    &::before {
      width: 10px;
      top: 0;
    }
    &::after {
      top: 10px;
      width: 24px;
    }
    span {
      top: 5px;
      width: 16px;
    }
    &:hover {
      &::before,
      &::after,
      span {
        width: 100%;
      }
    }
  }
  &__list {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  @media (max-width: 670px) {
    flex-grow: 0;
    &__burger {
      width: 44px;
      height: 32px;
      &::before {
        width: 18.33333396911621px;
        top: 10px;
      }
      span {
        width: 29.333332061767578px;
        top: 15px;
      }
      &::after {
        width: 44px;
        top: 20px;
      }
    }
    &__list {
      display: none;
    }
  }
  @media (max-width: 500px) {
    &__logo {
      max-height: 30px;
    }
  }
}
