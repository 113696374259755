$warrior: linear-gradient(82.76deg, #792721 -8.67%, #ff655f 44.75%);
$rogue: linear-gradient(123.98deg, #30ca72 30.32%, #429841 106.48%);
$mage: linear-gradient(82.77deg, #f99f2f 5.38%, #fbf6cd 44.75%);

.play {
  padding-top: 144px;
  padding-bottom: 74px;
  &__inner {
    position: relative;
    &::before,
    &::after {
      content: "";
      position: absolute;
      filter: blur(150px);
      opacity: 0.15;
    }
    &::before {
      background: #c5332d;
      width: 906px;
      height: 468px;
      left: 0;
      bottom: -144px;
      z-index: -1;
    }
    &::after {
      background: #726b62;
      height: 1010.2646921827122px;
      width: 886.4770966449968px;
      right: -250px;
      top: -230px;
      z-index: -1;
      transform: rotate(-45deg);
    }
  }

  &__subtitle {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #96764b;
    margin-bottom: 24px;
  }
  .title {
    max-width: 630px;
    margin: 0 auto 40px auto;
  }

  &__content {
    position: relative;
  }

  &-slider {
    max-width: 1029px;
    width: 100%;
    padding-bottom: 200px;
    &__slide {
      width: 448px;
      height: 540px;
      background-position: center;
      background-size: contain;
      position: relative;
      padding-top: 40px;
      padding-bottom: 40px;
      &:not(.swiper-slide-visible) {
        opacity: 0;
      }
      &::after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        z-index: -1;
      }
      &-warrior {
        &::after {
          background: url("../../../assets/heroes/warrior-bg.png") center / cover no-repeat;
        }
      }
      &-rogue {
        &::after {
          background: url("../../../assets/heroes/rogue-bg.png") center / cover no-repeat;
        }
      }
      &-mage {
        &::after {
          background: url("../../../assets/heroes/mage-bg.png") center / cover no-repeat;
        }
      }
    }
    &.webp {
      .play-slider__slide {
        &-warrior {
          &::after {
            background: url("../../../assets/heroes/warrior-bg.webp") center / cover no-repeat;
          }
        }
        &-rogue {
          &::after {
            background: url("../../../assets/heroes/rogue-bg.webp") center / cover no-repeat;
          }
        }
        &-mage {
          &::after {
            background: url("../../../assets/heroes/mage-bg.webp") center / cover no-repeat;
          }
        }
      }
    }
    &__slide-img {
      display: block;
      width: 100%;
      height: 100%;
      max-width: 100%;
      object-fit: contain;
    }
    .swiper-button-prev,
    .swiper-button-next {
      cursor: pointer;
      width: 80px;
      height: 80px;
      background: rgba(46, 44, 44, 0.6);
      border-radius: 24px;
      left: auto;
      right: auto;
      &:after {
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        background-image: linear-gradient(152.08deg, #d4ab79 47.7%, #d9aa5e 168.73%);
      }
    }
    .swiper-button-prev {
      left: -16px;
    }
    .swiper-button-next {
      right: -16px;
    }
  }
  &__descriptions {
    position: absolute;
    z-index: 1;
  }
  &-description {
    $playDescription: &;
    &:not(.play-description-active) {
      display: none;
    }
    &__inner {
      max-width: 1008px;
      margin-left: auto;
      margin-right: auto;
    }
    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 40px;
    }

    &__title {
      display: flex;
      align-items: center;
      gap: 24px;
    }

    &__title-img {
    }

    &__title-text {
      font-weight: 900;
      font-size: 48px;
      line-height: 120%;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      text-transform: capitalize;
      &-warrior {
        background-image: $warrior;
      }
      &-rogue {
        background-image: $rogue;
      }
      &-mage {
        background-image: $mage;
      }
    }

    &__text {
      font-weight: 400;
      font-size: 13px;
      line-height: 140%;
      color: rgba(#ffffff, 0.6);
    }
  }
  @media (max-width: 1200px) {
    padding-top: 70px;
    padding-bottom: 40px;
    &__inner {
      padding: 0;
    }
    &-description {
      &__inner {
        width: calc(100% - 90px);
      }
      &__item {
        flex-direction: column;
      }
    }
    .arrows {
      top: auto;
      bottom: 0;
      transform: translate(-50%, -100%);
      left: 50%;
      width: calc(100% - 90px);
    }
  }
  @media (max-width: 924px) {
    &-slider {
      &__slide {
        padding-top: 20px;
        padding-bottom: 20px;
        width: 373.33px;
        height: 450px;
      }
    }
  }
  @media (max-width: 768px) {
    &-slider {
      &__slide {
        width: 290.37px;
        height: 350px;
      }
    }
    .arrows {
      transform: translate(-50%, -150%);
    }
  }
  @media (max-width: 650px) {
    &-description {
      &__item {
        gap: 24px;
      }
      &__title {
        &-img {
          display: none;
        }
      }
      &__text {
        min-height: 55px;
      }
    }
    .arrows {
      transform: translate(-50%, -150%);
      bottom: auto;
      top: 50%;
      z-index: 5;
      width: calc(100% - 20px);
    }
  }
  @media (max-width: 500px) {
    .arrows {
      display: none;
    }
  }
}
