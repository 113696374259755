.socio {
  &__link {
    padding: 32px 48.74px;
    background: rgba(35, 34, 32, 0.4);
    border-radius: 24px;
    display: inline-flex;
    width: 100%;
    flex-direction: column-reverse;
    gap: 12px;
    align-items: center;
    justify-content: center;
  }

  &__title {
    font-family: "Inter";
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
  }
}
