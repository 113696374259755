.tokenomic {
  padding-top: 138px;
  &__inner {
    background: linear-gradient(180deg, rgba(35, 34, 32, 0.4) 0%, rgba(35, 34, 32, 0) 123.88%);
    border-radius: 60px;
    padding: 60px 24px;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: -138px;
      height: 1273.5px;
      width: 100%;
      background: linear-gradient(136.32deg, #379133 16.32%, #54e1ff 66.79%, #54e1ff 85.53%);
      filter: blur(235.137px);
      opacity: 0.1;
      z-index: -1;
    }
  }
  .arrows {
    top: 0;
    transform: translate(0);
    z-index: 2;
  }
  &-switcher {
    display: flex;
    align-items: center;
    max-width: 923px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    margin-bottom: 40px;
    &__item {
      max-width: 33.333%;
      width: 100%;
      text-align: center;
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      letter-spacing: 0.02em;
      color: rgba(#ffffff, 0.6);
      position: relative;
      padding-bottom: 6px;
      cursor: pointer;
      &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        background: rgba(#d9d9d9, 0.2);
      }
      &-active {
        color: #ffffff;
        &:after {
          background: linear-gradient(82.77deg, #4da9ff 5.38%, #54e0ff 44.75%);
        }
      }
    }
  }
  &-map {
    max-width: 1120px;
    margin-left: auto;
    margin-right: auto;
    &__inner {
      position: relative;
    }

    &__separator {
      width: 100%;
      height: 3px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) -6.48%, #ffffff 45.65%, rgba(255, 255, 255, 0) 100%);
      opacity: 0.4;
      border-radius: 20px;
      margin: 120px 0 40px 0;
    }

    &__buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      .button-green {
        min-width: 160px;
      }
    }
    &-item {
      &__inner {
      }

      &__title {
        font-weight: 900;
        font-size: 62px;
        line-height: 100%;
        text-align: center;
        background: linear-gradient(82.77deg, #4da9ff 5.38%, #54e0ff 44.75%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        margin-bottom: 24px;
      }

      &__subtitle {
        font-weight: 400;
        font-size: 13px;
        line-height: 140%;
        text-align: center;
        color: rgba(#ffffff, 0.6);
        margin-bottom: 64px;
      }

      &__image {
        text-align: center;
        &-img {
          max-width: 100%;
          object-fit: cover;
        }
      }
    }
  }
  @media (max-width: 900px) {
    &-switcher {
      &__item {
        font-size: 21px;
        line-height: 32px;
      }
    }
    &-map-item {
      &__title {
        font-size: 40px;
      }
    }
  }
  @media (max-width: 760px) {
    &__inner {
      background: none;
      padding: 0;
    }
    .arrows {
      display: none;
    }
    &-switcher {
      padding-left: 24px;
      overflow-x: auto;
      -ms-overflow-style: none; /* Internet Explorer 10+ */
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }
      &__item {
        padding: 0 16px;
        min-width: fit-content;
        max-width: 100%;
      }
    }
    &-map__separator {
      margin-top: 24px;
      margin-bottom: 40px;
    }
    &-map-item {
      &__inner {
        overflow-x: auto;
      }
      &__subtitle {
        margin-bottom: 24px;
      }
      &__image {
        overflow-x: auto;
        padding-left: 24px;
        padding-right: 24px;
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
          display: none; /* Safari and Chrome */
        }
        &-img {
          max-width: none;
          width: 1120px;
        }
      }
    }
  }
  @media (max-width: 500px) {
    padding-top: 80px;
  }
  @media (max-width: 418px) {
    &-map__separator {
      width: calc(100% - 48px);
      margin-left: auto;
      margin-right: auto;
    }
    &-map__buttons {
      padding: 0 24px;
      flex-direction: column;
      .button {
        width: 100%;
      }
    }
  }
}
